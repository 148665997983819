@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&amp;display=swap');
@import './css/vendor/bootstrap.min.css';
//@import 'bootstrap/dist/css/bootstrap.min.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import './css/slick_eski.css';
@import './css/icofont.min.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.css?ver=4.9.1');
@import './css/animate.css';
@import './css/nice-select.css';
@import './css/magnific-popup.css';
@import './css/style.scss';
@import './fonts/hypeskool/stylesheet.css';

@font-face {
    font-family: 'cutlass_regularregular';
    src: url('./fonts/cutlass_regular-webfont.woff2') format('woff2'), url('./fonts/cutlass_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Medieval Sharp';
    src: url('./fonts/medieval-sharp/MedievalSharp-BoldOblique.woff2') format('woff2'),
        url('./fonts/medieval-sharp/MedievalSharp-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Medieval Sharp';
    src: url('./fonts/medieval-sharp/MedievalSharp.woff2') format('woff2'),
        url('./fonts/medieval-sharp/MedievalSharp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Medieval Sharp';
    src: url('./fonts/medieval-sharp/MedievalSharp-Bold.woff2') format('woff2'),
        url('./fonts/medieval-sharp/MedievalSharp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Medieval Sharp';
    src: url('./fonts/medieval-sharp/MedievalSharp-Oblique.woff2') format('woff2'),
        url('./fonts/medieval-sharp/MedievalSharp-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/*@font-face {
    font-family: 'zalamender';
    src: url('./fonts/zalamender/ZalamanderCaps-Regular.otf.html') format('opentype');
    font-weight: normal;
    font-style: normal;
}*/

.fs_35 {
    font-size: 33px;
}

.fs_60 {
    font-size: 60px;
}

.ln45 {
    line-height: 45px !important;
}

iframe {
    transition: opacity 500ms ease-in-out;
    transition-delay: 250ms;
}

.row.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.row.no-gutters>[class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

.o-50 {
    opacity: 0.5 !important;
}

.o-70 {
    opacity: 0.7 !important;
}

.o-80 {
    opacity: 0.8 !important;
}

.o-85 {
    opacity: 0.85 !important;
}

.o-90 {
    opacity: 1 !important;
}

.o-100 {
    opacity: 1 !important;
}

.text_1 {
    font-family: cutlass_regularregular, sans-serif !important;
    text-shadow: 1px 1px 1px #16a085, 1px 1px 4px #2980b9;
    font-size: 50px;
}

.text_1_1 {
    font-family: cutlass_regularregular, sans-serif !important;
}


.text_2 {
    font-family: Exo, sans-serif !important;
    text-shadow: 1px 1px 1px #16a085, 1px 1px 4px #2980b9;
}

.text_3 {
    font-family: "Metal Mania", cursive;
}

.text_4 {
    font-family: "Oooh Baby", cursive;
}

.text_5 {
    font-family: "hypeskoolmedium", cursive;
}

.text_6 {
    font-family: "MedievalSharp", cursive;
}

.text_7 {
    font-family: "MedievalSharp", cursive;
}

.siyah {
    color: #000;
}

.shadow_1 {
    text-shadow: 3px 3px 0px #291a27;
}

.shadow_2 {
    text-shadow: 1px 1px 1px #b154f0;
}

.shadow_3 {
    text-shadow: 3px 1px 1px #b154f0;
}

.shadow_4 {
    text-shadow: 2px 2px 2px #291a27;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'cutlass_regularregular';
    font-size: 2em !important;
}

video.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#player-overlay_4 {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: -999;
}

.video-background-2 {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
}

#video-background {
    //background: url('./video/video-bg.png');
    background-size: contain;
    right: 0;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
}

article {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.button2 {
    position: relative;
    border-radius: 5px;
    border: 0;
    font-size: 1.5rem;
    font-family: Oswald, sans-serif;
    color: #fff;
    background-color: #2980b9;
    padding: .4em;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.button2:hover {
    background-color: #3498db;
}

.ani_1_mt {
    margin-top: 90px;
}

.ani_2_mt {
    margin-top: 100px;
}

.ani_1_mb {
    margin-bottom: 30px;
}

.ani_2_mb {
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .carousel-inner .carousel-item>div {
        display: none;
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
    transform: translateX(0);
}

html,
body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.slick-slide {
    //margin: 0px 10px;
}

.slick-slide img {
    width: 100%;
}

.slick-prev:before,
.slick-next:before {
    color: black;
}

.slick-slide {
    transition: all ease-in-out .3s;
    opacity: 1;
}

.slick-active {
    opacity: 1;
}

.slick-current {
    opacity: 1;
}

h1 {
    text-transform: none;
}

.ani_1 {
    font-size: 160px;
    margin-bottom: -35px;
    padding-top: 65px;
    padding-bottom: 45px;
}

.text_1 {
    font-family: 'MedievalSharp' !important;
    font-size: 65px !important;
}

.text_1_1 {
    font-family: 'MedievalSharp' !important;
    font-size: 30px !important;
}

.text_5 {
    font-family: 'MedievalSharp' !important;
    font-size: 27px !important;
}

.font-5 {
    font-family: 'MedievalSharp' !important;
    font-size: 20px !important;
}

footer input,
input[type="submit"] {
    font-family: 'MedievalSharp' !important;
    font-size: 20px !important;
    height: 45px;
    border: 0;
    vertical-align: bottom;
    padding-left: 10px;
    padding-right: 10px;
}

footer .text_1 {
    font-size: 35px !important;
}

.font-0 a {
    font-family: 'MedievalSharp';
    font-size: 20px !important;
}

.font-1 {
    font-family: 'Medieval Sharp';
    font-size: 80px;
    text-shadow: 1px 1px 0px #000;
    text-transform: uppercase;
    font-weight: bold;
}

.font-2 {
    font-family: 'Medieval Sharp';
    font-size: 40px;
    text-shadow: 1px 1px 0px #fff;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: bold;
    color: #f46e32 !important;
}

.main_menu nav>ul>li>a::before {
    background: none !important;
}

.main_menu nav>ul>li {
    margin-right: 15px;
    position: relative;
    padding: 5px;
    text-shadow: 1px 1px 0px #291a27;
}

.main_menu nav>ul>li:hover {
    background-color: #DD6117;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.sing_up_btn .btn.btn-link {
    font-family: 'MedievalSharp';
    font-size: 20px !important;
}

.btn.btn-link {
    font-family: 'MedievalSharp';
    font-size: 25px !important;
    text-shadow: none;
}

.btn.btn-link img {
    margin-left: 0;
    margin-bottom: 0;
}

.btn.btn-link:hover img {
    margin-left: 5px;
}

.copyright_right p,
.footer_bottom_link_menu a {
    font-family: 'MedievalSharp';
    font-size: 20px !important;
}

@media (max-width: 500px) {
    .ani_1 {
        font-size: 80px;
        line-height: 60px;
        margin-bottom: 10px;
        margin-top: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .ani_2 {
        font-size: 25px;
        line-height: 40px;
    }
}

.roadmap-list {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-family: 'Medieval Sharp';
        color: #4c2e1b;
        font-size: 19px;
        padding-top: 40px;
        width: 75%;
        margin: 0 auto;
    }

    li {
        //background-image: url('./img_y/roadmap/FICI.png');
        //background-repeat: no-repeat;
        padding-left: 2em;
        background-size: 32px;
        text-align: left;
        position: relative;

        span {
            margin-top: -2px;
        }
    }

    .finished:before {
        background-image: url('./img_y/check.png');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 17px;
        height: 22px;
        content: "";
        position: absolute;
        margin-left: -1.3em;
        margin-top: 0.3rem;
    }

    .waiting:before {
        background-image: url('./img_y/remove.png');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 17px;
        height: 22px;
        content: "";
        position: absolute;
        margin-left: -1.3em;
        margin-top: 0.3rem;
    }
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    font-family: 'Medieval Sharp';
    font-size: 18px !important;
    text-transform: uppercase;
    font-weight: bold;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    font-family: 'Medieval Sharp';
    font-size: 16px !important;
}

.row-content-text {
    padding: 12px !important;
}

.faq-row ul {
    list-style-type: circle !important;
}

.faq-row ul li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1em;
    margin-top: 10px;
}

.faq-row ul li:last-child {
    margin-bottom: 10px;
}

.row-content-text {
    height: auto !important;
}

.faq-row:last-child {
    border: 0 !important;
}

body.home-page {
    background: url('./img_y/bg123.jpg');
    background-size: cover;
}

body.faq-page {
    background: url('./img_y/faq.jpg') #01207d no-repeat;
}

.home-video {
    position: absolute;
    transition: opacity, 2s ease-in-out;
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 1001;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f46e32;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #01207d;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(./img_y/bg123.jpg) center center;
    background-size: cover;
    z-index: 1000;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.loaded #loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
}

@media (max-width: 800px) {

    .text_5,
    .text_1_1 {
        line-height: 27px !important;
    }

    .text-warning {
        margin-top: 10px !important;
    }

    .main_header {
        padding: 0 !important;
        line-height: 70px !important;
    }

    .header_logo {
        margin-top: 10px !important;
    }

    .canvas_open {
        top: 20px !important;
        right: 0 !important;

        button {
            font-size: 30px !important;
        }

        button:hover {
            color: #fff !important;
        }
    }

    .header_right_sidebar {
        margin-top: 0 !important;
    }

    .sticky-header.sticky {
        position: fixed !important;
        background: rgba(20, 14, 56, 0.9) !important;
        display: block !important;
        padding-top: 15px;
        padding-right: 5px;
        padding-bottom: 5px;
    }

    #hero-banner-section {
        height: 100vh !important;

        .font-1 {
            font-size: 9vw !important;
            padding: 8px !important;
        }

        .font-2 {
            font-size: 4.5vw !important;
            padding: 8px !important;
            padding-top: 0 !important;
        }

        .btn.btn-link {
            font-size: 6vw !important;
            width: 50vw !important;
            background-size: contain !important;
        }
    }

    #welcome-oceanland-section {
        .section_title img {
            width: 300px !important;
            margin-top: 30px !important;
            margin-bottom: 20px !important;
        }

        .story_of_o {
            width: 65vw !important;
            margin: 0 auto !important;
            margin-top: -130px !important;
        }

        .text_5 {
            font-size: 5.5vw !important;
        }

        .mt-40 {
            margin-top: 0 !important;
        }

        .mb-40 {
            margin-bottom: 10px !important;
        }
    }

    #game-story-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        .mt-20 {
            margin-top: 0 !important;
        }

        .story_of_o {
            width: 65vw !important;
            margin: 0 auto !important;
            margin-top: -90px !important;
        }

        .text_5 {
            font-size: 5.5vw !important;
        }

        .mt-40 {
            margin-top: 0 !important;
        }

        .mb-40 {
            margin-bottom: 10px !important;
        }
    }

    #resources-1-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        .mt-40 {
            margin-top: 0 !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }
    }

    #nft-tools-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        &.mb-105 {
            margin-bottom: 30px !important;
        }
    }

    #how-to-obtain-nfts-1-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
            margin-top: -100px !important;
        }

        .gaming_video_thumb {
            max-width: 99% !important;
            margin: 0 auto !important;

            .col-md-4 {
                margin-bottom: 20px !important;
            }
        }

        .section_title.mb-60 {
            margin-bottom: 30px !important;
        }
    }

    #how-to-obtain-nfts-2-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }

        .gaming_video_thumb {
            max-width: 99% !important;
            margin: 0 auto;
        }
    }

    #roadmap-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }

        .row {
            .col-md-3 {
                margin-bottom: -170px !important;
                margin-top: -50px !important;

                div:nth-child(1) {
                    margin-top: 0 !important;
                }

                div:nth-child(1)+div {
                    margin-top: -30px !important;
                }

                .roadmap-list {
                    margin-top: -25px !important;
                }
            }

            .col-md-3:first-child {
                margin-top: 0 !important;
            }
        }
    }

    #partners-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }
    }

    #treasure-tokens-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_1_1 {
            font-size: 7vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }

        .gaming_video_thumb {
            max-width: 99% !important;
            margin: 20px auto !important;

            .col-md-4 {
                margin-bottom: 20px !important;
            }
        }
    }

    #token-allocation-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_1_1,
        .text_5 {
            font-size: 5vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }
    }

    #team-section {
        .text_1 {
            font-size: 10vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .text_5 {
            font-size: 3.1vw !important;
        }

        &.mb-222 {
            margin-bottom: 30px !important;
        }

        .p-40 {
            padding: 8px !important;
        }

        .yuzdeli_yazilar_1 {
            width: 30px !important;
        }
    }

    .main_footer {
        .text_1 {
            font-size: 8vw !important;
            margin-bottom: 10px !important;
            line-height: inherit !important;
        }

        .mt-40,
        .mb-40 {
            margin: 10px;
        }

        .twitter-widget-0 {
            width: 300px !important;
        }

        .main_footer_inner {
            display: none !important;
        }
    }

    .footer_bottom {

        .copyright_right p,
        .footer_bottom_link_menu p,
        .footer_bottom_link_menu a {
            font-size: 4vw !important;
        }
    }
}

.offcanvas-header {
    button {
        font-size: 20px !important;
    }
}

.offcanvas-body {
    padding: 1.5rem 1.9rem !important;
}

.offcanvas_main_menu {
    .menu-item-has-children:nth-child(1) {
        position: absolute !important;
        top: 30px !important;
    }

    .menu-item-has-children:not(:first-child):not(:last-child) a {
        width: 90% !important;
    }

    .menu-item-has-children:nth-child(2) {
        margin-top: 40px !important;
    }
}

.pr-0 {
    padding-right: 0px !important;
}

@media (max-width: 320px) {
    #roadmap-section {
        .row {
            .col-md-3 {
                margin-bottom: -130px !important;
                margin-top: -30px !important;

                div:nth-child(1) {
                    margin-top: 0 !important;
                }

                div:nth-child(1)+div {
                    margin-top: 5px !important;
                }

                .roadmap-list {
                    margin-top: 5px !important;
                }
            }

            .col-md-3:first-child {
                margin-top: 0 !important;
            }
        }
    }

    #team-section {
        .text_5 {
            font-size: 2.8vw !important;
        }
    }

    .main_footer {
        .text_1 {
            font-size: 6vw !important;
        }

        .twitter-widget-0 {
            width: 230px !important;
        }

        .main_footer_inner {
            display: none !important;
        }

        input {
            width: 50% !important;
        }

        input[type=submit] {
            width: 35% !important;
        }
    }

    .footer_bottom {

        .copyright_right p,
        .footer_bottom_link_menu p,
        .footer_bottom_link_menu a {
            font-size: 4vw !important;
        }
    }
}

.offcanvas_main_menu {
    a {
        color: #fff !important;
    }

    .menu-item-has-children:last-child {
        a {
            border: 0 !important;
        }
    }
}

.offcanvas-header .btn-close {
    color: #fff !important;
    opacity: 1 !important;
}

@media (max-width: 800px) {
    .page_wrapper  {
        overflow-x: hidden;
        width: 100vw;
    }
}

.bg-white {
    background: #ffffff !important;
}